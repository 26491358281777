import { AxiosResponse } from "axios";
import ApiService from "@/services";
import ShippingParameterAPIInterface from "@/types/shipping-parameters/ShippingParameterAPIInterface";
import { UpdateProductionSpeedParameters } from "@/types/admin-parameters/ProductionSpeedParameters";
import AuthorizationParametersInterface from "@/types/admin-parameters/AuthorizationParametersInterface";
import { UpdateManagementAndProfitInterface } from "@/types/admin-parameters/ManagementAndProfitInterface";
import { UpdateRiskInterface } from "@/types/admin-parameters/RiskInterface";
import { UpdateQuantityInterface } from "@/types/admin-parameters/QuantityInterface";
import OtherParametersInterface from "@/types/admin-parameters/OtherParametersInterface";
import { InspectionCostUpdateInterface } from "@/types/admin-parameters/InspectionCostInterface";
import { ReportsDataInterface } from "@/types/admin-parameters/SpecialReportsInterface";

export default class ParameterService {
  static getShippingParameters(customerRegion: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/params/order-params", {
      params: {
        customerRegion,
      },
    });
  }

  static updateShippingParameters(params: {
    updated: ShippingParameterAPIInterface[];
    created: ShippingParameterAPIInterface[];
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/params/order-params/shipping", {
      ...params,
    });
  }

  static getAuthorizationParameters(): Promise<AxiosResponse> {
    return ApiService.get("/v1/params/order-params/authorization");
  }

  static updateAuthorizationParameter(
    payload: Partial<AuthorizationParametersInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch("/v1/params/order-params/authorization", payload);
  }

  static getProductionSpeedParameters(region: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/params/order-params/production-speed", {
      params: { region },
    });
  }

  static updateProductionSpeedParameter(
    payload: UpdateProductionSpeedParameters
  ): Promise<AxiosResponse> {
    return ApiService.post("v1/params/order-params/production-speed", {
      update: [payload],
    });
  }

  static getFinancialParameters(mfrRegion: string): Promise<AxiosResponse> {
    return ApiService.get("v1/params/order-params/financial", {
      params: {
        mfrRegion,
      },
    });
  }

  static getExchangeRates(): Promise<AxiosResponse> {
    return ApiService.get("v1/exchange-rates");
  }

  static updateExchangeRate(payload: {
    currencyId: string;
    rate: number;
  }): Promise<AxiosResponse> {
    return ApiService.post("v1/exchange-rates", payload);
  }

  static updateManagementAndProfit(
    payload: UpdateManagementAndProfitInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      "/v1/params/order-params/financial/management",
      payload
    );
  }

  static updateRisk(payload: UpdateRiskInterface): Promise<AxiosResponse> {
    return ApiService.patch("/v1/params/order-params/financial/risk", payload);
  }

  static updateQuantity(
    payload: UpdateQuantityInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      "/v1/params/order-params/financial/quantity",
      payload
    );
  }

  static getOtherParameters(mfrRegion: string): Promise<AxiosResponse> {
    return ApiService.get("v1/params/order-params/financial/other", {
      params: {
        mfrRegion,
      },
    });
  }
  static updateOtherParameters(
    params: Partial<OtherParametersInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/params/order-params/financial/other`, null, {
      params,
    });
  }

  static getInspectionCostParameters(
    mfrRegion: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/params/order-params/financial/quality-control", {
      params: { mfrRegion },
    });
  }
  static updateInspectionCostParameters({
    id,
    ...payload
  }: InspectionCostUpdateInterface): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v1/params/order-params/financial/quality-control/${id}`,
      payload
    );
  }

  static getSpecialReportsParameters(
    mfrRegion: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/params/order-params/financial/reports", {
      params: { mfrRegion },
    });
  }
  static updateSpecialReportsParameters(payload: {
    update: ReportsDataInterface[];
    create: ReportsDataInterface[];
  }): Promise<AxiosResponse> {
    return ApiService.post(
      "/v1/params/order-params/financial/reports",
      payload
    );
  }
}
