const ManufacturerRegions = [
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "China",
    value: "China",
  },
];

export default ManufacturerRegions;
